import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'app/common/services';
import { IParameterValues } from 'app/components/parameter-values/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IParameterModal } from '../models';

@Component({
    selector: 'app-parameter-modal',
    templateUrl: './parameter-modal.component.html',
    styleUrls: ['./parameter-modal.component.scss']
})
export class ParameterModalComponent implements OnInit, OnDestroy {
    protected unsubscribe = new Subject<void>();
    public parameterValues?: IParameterValues;
    public typeId: number;
    public typeGroupId: number;
    public categoryId: number;

    constructor(
        public readonly dialogRef: MatDialogRef<ParameterModalComponent, IParameterValues>,
        @Inject(MAT_DIALOG_DATA) public data: IParameterModal,
        private readonly messageService: MessageService
    ) {
        this.parameterValues = data.parameterValues;
        this.categoryId = data.categoryId;
        this.messageService
            .getMessage('typeId')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((typeId) => (this.typeId = typeId));
        this.messageService
            .getMessage('typeGroupId')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((typeGroupId) => (this.typeGroupId = typeGroupId));
    }

    ngOnInit() {
        this.messageService.sendMessage('categoryId', this.data.categoryId);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public updateParameterValue(values: IParameterValues) {
        Object.keys(values).forEach((key) => {
            this.parameterValues[key] = values[key];
        });
    }

    public ok() {
        this.dialogRef.close(this.parameterValues);
    }
}
