import { Validators } from '@alza/cms-components';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-parameter-url-modal',
    templateUrl: './parameter-url-modal.component.html',
    styleUrls: ['./parameter-url-modal.component.scss']
})
export class ParameterUrlModalComponent implements OnInit {
    public form: UntypedFormGroup;
    constructor(public readonly dialogRef: MatDialogRef<ParameterUrlModalComponent, string>, public fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            url: [null, Validators.url('absolute')]
        });
    }
    public ok() {
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.dialogRef.close(this.form.get('url').value);
        }
    }
}
