<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close>
        <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>
    <h4>{{ 'Category_Parameters_Modal_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="row">
        <div class="col-xs-12 col-md-7">
            <div class="ibox">
                <div class="ibox-content">
                    <app-parameter-type-select-groups></app-parameter-type-select-groups>
                </div>
            </div>
            <div class="ibox">
                <div class="ibox-content">
                    <app-parameter-type-select-types></app-parameter-type-select-types>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-md-5">
            <div class="ibox">
                <div class="ibox-content">
                    <app-parameter-values
                        [parameterValues]="parameterValues"
                        (valueChanged)="updateParameterValue($event)"
                        [type]="typeId"
                        [typeGroup]="typeGroupId"
                        [categoryId]="categoryId"
                    >
                    </app-parameter-values>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
    <button data-tid="btnOk" mat-dialog-ok type="button" (click)="ok()" class="btn btn-primary">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
