<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close>
        <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>
    <h4>{{ 'Category_Parameters_ProcessUrl' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="row" *ngIf="form" [formGroup]="form">
        <div class="col-xs-12">
            <cms-input [id]="'url'" [labelText]="'Category_Parameters_Url' | translate" [formControl]="form.get('url')"> </cms-input>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
    <button data-tid="btnOk" mat-dialog-ok type="button" (click)="ok()" class="btn btn-primary">{{ 'Category_Parameters_Process' | translate }}</button>
</mat-dialog-actions>
